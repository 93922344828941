/*jshint esversion: 6 */
import Ajax from '../common/Ajax';
import * as Utils from '../common/Utils';
import WidgetSnippets from '../widgets/WidgetSnippets';

let WidgetBanner = function(config){

    const BANNER_TYPE_STANDARD = 'standard';
    const BANNER_TYPE_SNIPPET = 'snippet';

    function createRoute () {
        return Utils.generateRouteFromPattern({
            pattern : {
            },
            route : config.route,
            env : config.env,
            locale: config.locale
        });
    }

    function getSnippets(tags, design, maxItems, order, item) {

        let snippetTool = WidgetSnippets({
            locale: LOCALE,
            env: ENV,
            route: "/widget/snippets/get/%tagstring%"
        });

        snippetTool.getSnippetsByTags(tags, design, maxItems, order, item);

    }

    function getBannerType(url) {
        if (url && (url.substring(0, 7) === 'snippet')) {
            return BANNER_TYPE_SNIPPET;
        }
        return BANNER_TYPE_STANDARD;
    }

    function createStandardBanner(item, response) {

        let banner = document.createElement('a');
        banner.classList.add('banner-link');
        banner.classList.add(`banner-id-${response.id}`);
        banner.href = response.url;
        banner.title = response.description;
        banner.target = getLinkTarget(response.url);

        Utils.addCrossBrowserEventListener(banner, 'click', function(event) {
            Utils.sendCustomEvent('banner_click', {
                source: 'ajax',
                bannerId: response.id,
                targetUrl: banner.href
            });
        });

        let image = document.createElement('img');
        image.src = response.src;
        image.width = response.width;
        image.height = response.height;
        image.alt = response.description;

        banner.appendChild(image);
        item.appendChild(banner);

        let bannerName = `banner-${response.id}`
        item.setAttribute('name', bannerName);

        // support a second URL
        if (response.url2) {
            let link2 = document.createElement('a');
            link2.classList.add('banner-link');
            link2.classList.add('banner-2nd-link');
            link2.classList.add(`banner-id-${response.id}`);
            link2.href = response.url2;
            link2.target = getLinkTarget(response.url2);
            link2.text = response.url2LinkText;

            Utils.addCrossBrowserEventListener(link2, 'click', function(event) {
                Utils.sendCustomEvent('banner_click', {
                    source: 'ajax',
                    bannerId: response.id,
                    targetUrl: link2.href
                });
            });

            item.appendChild(link2);
        }
    }

    // For internal ecancer links then the target is the same tab.
    // For external site links, the target should be _blank.
    function getLinkTarget(url) {
        let urlObj = new URL(url);
        let domain = urlObj.hostname;
        if (domain.indexOf('ecancer') > -1) {
            return '_self';
        }
        return '_blank';
    }

    function createSnippetBanner(item, response){
        let tagsString = response.url.substring(8).replace(/_/g, ' ');
        getSnippets(tagsString, '', 1, '', item);
        let bannerName = `banner-${response.id}`
        item.setAttribute('name', bannerName);
    }

    let getBanner = function (item) {

        let route = createRoute();

        let success = function(response){

            if (!response) {
                console.log('no banner found!');
                return;
            }

            let bannerType = getBannerType(response.url);

            switch(bannerType){

                case BANNER_TYPE_SNIPPET:
                    createSnippetBanner(item, response);
                    break;

                default:
                    createStandardBanner(item, response);
                    break;
            }

            Utils.sendCustomEvent(`banner_visible`, {'source': 'ajax', 'bannerId': response.id});

        };

        let error = function(response){
            console.log('ERROR');
            console.log(response);
        };

        Ajax.getRequest(route, success, error);

    }


    return {
        getBanner: getBanner
    };

};


export default WidgetBanner;
