/*jshint esversion: 6 */

import WidgetBanner from '../widgets/WidgetBanner';
import foreachPolyfill from "../common/polyfill-foreach";



window.addEventListener("load",(event) => {

    function getBanner(item) {

        let BannerTool = WidgetBanner({
            locale: LOCALE,
            env: ENV,
            route: "/widget/banner"
        });

        BannerTool.getBanner(item);

    }

    document.querySelectorAll('.banner').forEach(item => {

        getBanner(item);

    });


});

